<template>
  <pre>
      <code v-html="viewer" class="hljs"></code>
  </pre>
</template>

<script>
const hljs = require("highlight.js/lib/common");

export default {
  props: {
    language: String,
    code: String,
  },
  data() {
    return {
      viewer: "",
    };
  },
  mounted() {
    const code = this.code;
    const { value } = hljs.highlight(code, {
      language: this.language,
      ignoreIllegals: true,
    });
    this.viewer = value;
  },
};
</script>

<style>
pre code.hljs {
  font-family: consolas;
}
</style>
